
import { VForm } from '@/types';
import { Component, Vue } from "vue-property-decorator";
import { authService } from "@/services/authentification.service";

@Component
export default class Login extends Vue {
  private loading = false;
  private login: any = null;
  private password: any = null;
  private showPassword = false;
  private formHasErrors = false;
  private errorMessage = "";
  private showError = false;
  private rules = {
    requiredLogin: (value: boolean) => !!value || "Введите логин",
    requiredPass: (value: boolean) => !!value || "Введите пароль",
  };

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  mounted() {
    authService.logout();
  }

  async auth() {
    this.form?.validate();
    this.formHasErrors = false;
    this.showError = false;

    if (this.login && this.password) {
      this.loading = true;
      try {
        const responce = await authService.login(this.login, this.password);
        if (responce.error) {
          this.errorMessage = responce.error;
          this.showError = true;
          this.loading = false;
        } else {
          await this.$router.push("Home");
        }
      }
      catch (ex) {
        this.loading = false;
        this.showError = true;

        if (ex instanceof Error) {
          this.errorMessage = ex.message;
        } else {
          this.errorMessage = String(ex);
        }
      }
    }
  }
}
